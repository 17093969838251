import Head from 'next/head'
import React from 'react'

export default function UploadPage() {
  return (
    <Head>
      <title>Upload</title>
      <meta name="description" content="" />
    </Head>
  )
}
